import { configureStore } from '@reduxjs/toolkit';

import contentApi from './contentApi';
import rootReducer from './reducer';
import tilesApi from './tilesApi';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(contentApi.middleware).concat(tilesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
