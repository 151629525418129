/// <reference types="@helsenorge/framework-utils/types/hn"/>

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetTilesOperationResult, TextMessage, Tile } from '@helsenorge/framework-utils/types/entities';

import { AppDispatch } from '../store';
import { GlobalState } from '../store/reducer';

export interface TilesState {
  loading: boolean;
  errorLoad?: TextMessage;
  helsetjenester?: Tile[];
  innsynstjenester?: Tile[];
  eksterneTjenester?: Tile[];
  loaded: boolean;
}

const initialState: TilesState = {
  helsetjenester: [],
  innsynstjenester: [],
  eksterneTjenester: [],
  loading: false,
  errorLoad: undefined,
  loaded: false,
};

export const fetchTiles =
  () =>
  (dispatch: AppDispatch): void => {
    if (window.HN?.Commands?.GetTiles) {
      dispatch(requestTiles());
      dispatch(loadedTiles());
      dispatch(receiveTiles(window.HN.Commands.GetTiles));
    }
  };

export function getLoading(state: GlobalState): boolean {
  return state.tiles.loading;
}

export function getLoaded(state: GlobalState): boolean {
  return state.tiles.loaded;
}

export function getError(state: GlobalState): TextMessage | undefined {
  return state.tiles.errorLoad;
}

export function getHelsetjenesterTiles(state: GlobalState): Array<Tile> | undefined {
  return state.tiles.helsetjenester;
}

export function getInnsynstjenesterTiles(state: GlobalState): Array<Tile> | undefined {
  return state.tiles.innsynstjenester;
}

export function getEksterneTjenesterTiles(state: GlobalState): Array<Tile> | undefined {
  return state.tiles.eksterneTjenester;
}

const tilesSlice = createSlice({
  name: 'tiles',
  initialState,
  reducers: {
    requestTiles(state) {
      state.loading = true;
    },
    receiveTiles(state, action: PayloadAction<GetTilesOperationResult>) {
      state.helsetjenester = action.payload.Helsetjenester;
      state.innsynstjenester = action.payload.Innsynstjenester;
      state.eksterneTjenester = action.payload.EksterneTjenester;
      state.loading = false;
    },
    loadFailed(state, action: PayloadAction<{ error: TextMessage }>) {
      state.errorLoad = action.payload.error;
      state.loading = false;
    },
    loadedTiles(state) {
      state.loaded = true;
    },
  },
});

export const { requestTiles, receiveTiles, loadedTiles } = tilesSlice.actions;

export default tilesSlice.reducer;
