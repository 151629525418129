import { createApi } from '@reduxjs/toolkit/query/react';

import { GetAuthenticatedTilesResponse } from '@helsenorge/core-cms/types/entities/Helsenorge.CoreFrontend.InternalApiEntities';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getBaseQuery } from '@helsenorge/framework-utils/hn-proxy-service';

const tilesApi = createApi({
  reducerPath: 'tilesApi',
  baseQuery: getBaseQuery,
  endpoints: builder => ({
    getTiles: builder.query<GetAuthenticatedTilesResponse, LanguageLocales>({
      query: language => ['corefrontendinternal', 'AuthenticatedTiles/v1', { culture: language.toLowerCase() }],
    }),
  }),
});

export const { useGetTilesQuery } = tilesApi;

export default tilesApi;
