import { createApi } from '@reduxjs/toolkit/query/react';

import { ForsideDto } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getBaseQuery } from '@helsenorge/framework-utils/cms-content-api-service';

const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: getBaseQuery,
  endpoints: builder => ({
    getForside: builder.query<ForsideDto, LanguageLocales>({
      query: language => ['forside', { languageCode: language }],
    }),
  }),
});

export const { useGetForsideQuery } = contentApi;

export default contentApi;
