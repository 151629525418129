import { combineReducers, Reducer } from 'redux';

import pendingChanges, { PendingChangesState } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import hjelpeskuff, { HjelpeskuffState } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import { cmsTjenesteTekster, GlobalStateWithCmsTjenesteTekster } from '@helsenorge/framework-utils/cms-tjeneste-tekster';
import { resources, GlobalStateWithResources } from '@helsenorge/framework-utils/resources';

import contentApi from './contentApi';
import tilesApi from './tilesApi';
import tiles, { TilesState } from '../tjenestepanel-container/tiles';

const rootReducer: Reducer = combineReducers({
  resources,
  hjelpeskuff,
  tiles,
  pendingChanges,
  cmsTjenesteTekster,
  [contentApi.reducerPath]: contentApi.reducer,
  [tilesApi.reducerPath]: tilesApi.reducer,
});

export interface GlobalState extends GlobalStateWithResources, GlobalStateWithCmsTjenesteTekster {
  hjelpeskuff: HjelpeskuffState;
  tiles: TilesState;
  pendingChanges: PendingChangesState;
}

export default rootReducer;
